
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'styles/global.css';
import type { AppProps } from 'next/app';
import { ContextDTO } from 'lib/contextCreation';
import { UserContext } from 'lib/context';
import { Context } from 'lib/api';
import Head from 'components/app/Head';
import YandexMetrica from 'components/app/YandexMetrica';
import Sentry from 'components/app/Sentry';
import Notifications from 'components/app/Notifications';
import { Provider as JotaiProvider } from 'jotai';
import { enableMapSet } from 'immer';
import BrowserCheck from 'components/app/BrowserCheck';
import SidebarContainer from 'components/sidebar/SidebarContainer';
import Footer from 'components/app/Footer';
import Events from 'components/app/Events';
import ModalDispatcher from 'components/app/ModalDispatcher';
import NotificationArea from 'components/app/NotificationArea';
import 'core-js/modules/es.array.at';
import 'core-js/modules/es.string.at-alternative';
import 'core-js/modules/es.array.to-sorted';
import * as localePolyfill from 'lib/localePolyfill';
localePolyfill;
import { useRouter } from 'next/router';
import MallAnnouncement from 'components/app/MallAnnouncement';
import { c } from 'lib/component-utils';
import SellmonitorFooter from 'components/app/SellmonitorFooter';
import useTranslation from 'next-translate/useTranslation';
import FacebookMetrics from 'components/app/FacebookMetrics';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import PageLoadProgressBar from 'components/app/PageLoadProgressBar';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import SellematicsFooter from 'components/app/SellematicsFooter';
import { match } from 'ts-pattern';
import SellmonitorGPT from 'components/app/SellmonitorGPT';
import InterceptRouter from 'components/app/InterceptRouter';
import { motion } from 'framer-motion';
import VRTechTrackingPixel from 'components/app/VRTechTrackingPixel';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import JivoEvents from 'components/app/JivoEvents';
dayjs.extend(utc);
enableMapSet();
export type PageAppProps = {
    ctx: ContextDTO;
    isResponsive?: boolean;
    disableSidebar?: boolean;
    disableFooter?: boolean;
};
function NextApp(props: AppProps<PageAppProps>) {
    const { pageProps, Component } = props;
    const { err } = props as any;
    const { ctx, isResponsive, disableSidebar, disableFooter } = pageProps;
    const router = useRouter();
    const { lang } = useTranslation();
    if (!ctx) {
        return (<main>
        <Component {...pageProps} err={err}/>
      </main>);
    }
    return (<TooltipProvider delayDuration={50}>
      <ReCaptchaProvider>
        <JotaiProvider>
          <UserContext.Provider value={new Context(ctx)}>
            {ctx && (<>
                {process.env.NODE_ENV === 'production' && <JivoEvents />}
                {process.env.NODE_ENV === 'production' && ctx.theme.yandexMetrica && (<YandexMetrica />)}
                {process.env.NODE_ENV === 'production' &&
                ctx.theme.hostType === 'sellmonitor' &&
                router.asPath === '/' && <VRTechTrackingPixel />}
                {process.env.NODE_ENV === 'production' && ctx.theme.facebookMetrics && (<FacebookMetrics />)}
                {process.env.NEXT_PUBLIC_MERCURE_USER_URL && ctx.isAuthorized && <Events />}
                {process.env.NEXT_PUBLIC_SENTRY_ENABLED === '1' && <Sentry />}
                <Head isResponsive={isResponsive}/>
              </>)}
            <InterceptRouter />
            <BrowserCheck />
            <ModalDispatcher />
            <Notifications />
            <NotificationArea />
            <PageLoadProgressBar />
            <div className={c `flex w-full ${!isResponsive} min-w-[1170px]`}>
              {ctx?.isAuthorized && !disableSidebar && <SidebarContainer />}
              <main className="flex flex-col w-full min-h-screen bg-gray-100">
                {ctx.currentMall?.notification && router.asPath.includes(ctx.currentMall?.code) && (<MallAnnouncement />)}
                <motion.div className="bg-white" key={router.asPath.split('/').slice(0, 2).join('/')} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.4, ease: 'easeOut' } }} exit={{ opacity: 0, transition: { duration: 0, ease: 'easeIn' } }}>
                  <Component {...pageProps}/>
                </motion.div>
                {match(ctx.theme.hostType)
            .when(() => disableFooter, () => null)
            .when(() => lang === 'en', () => <Footer />)
            .with('sellematics', () => <SellematicsFooter />)
            .with('sellmonitor', () => <SellmonitorFooter />)
            .otherwise(() => (<Footer />))}
              </main>
            </div>
            {ctx.isAuthorized && ctx.malls.length > 0 && ctx.theme.hostType === 'sellmonitor' && (<SellmonitorGPT />)}
          </UserContext.Provider>
        </JotaiProvider>
      </ReCaptchaProvider>
    </TooltipProvider>);
}
const __Next_Translate__Page__190ebae9c13__ = NextApp;

    export default __appWithI18n(__Next_Translate__Page__190ebae9c13__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  